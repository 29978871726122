import React, { useRef, useState, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import { ValidationOptions } from 'react-hook-form';
import { IOption } from './interfaces/IOption';
import { SelectInput } from './components/SelectInput';
import { SelectWrapper } from './components/SelectWrapper';
import { InputWrapper } from '../InputWrapper';

interface IProps {
  name: string;
  label: string;
  className?: string;
  options: IOption[];
  disabled?: boolean;
  value?: string | number;
  error?: string | undefined;
  validation?: ValidationOptions;
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void;
  register?: (e: unknown, v: unknown) => void;
}

const Select = ({
  name,
  label,
  className,
  options,
  disabled,
  register,
  validation,
  error,
  onChange,
  value,
  ...rest
}: IProps): JSX.Element => {
  const [focussed, setFocus] = useState(false);
  const internalRef = useRef<HTMLSelectElement | null>(null);

  const focusClass = focussed || error ? 'focussed' : '';
  const errorClass = error ? 'error' : '';

  return (
    <InputWrapper>
      <SelectWrapper>
        <SelectInput
          name={name}
          className={`${className} ${focusClass} ${errorClass}`}
          disabled={disabled}
          ref={(e): void => {
            if (register) {
              register(e, validation);
              internalRef.current = e;
            }
          }}
          value={value}
          onChange={onChange}
          onFocus={(): void => setFocus(true)}
          onBlur={(): void => setFocus(false)}
          data-testid="select-input"
          {...rest}
        >
          <option value="">{label}</option>
          {options.map((option: IOption) => {
            return (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            );
          })}
        </SelectInput>
      </SelectWrapper>
    </InputWrapper>
  );
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string,
  validation: PropTypes.object,
  onChange: PropTypes.func,
  register: PropTypes.func,
};

Select.displayName = 'Select';

export default Select;

export { IOption };

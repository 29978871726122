import { cssVar } from '@chakra-ui/react';
import { StyleConfig } from '@chakra-ui/theme-tools';

export const TooltipGlobalStyle = {
  '[id^=tooltip]': {
    padding: '4px 8px 4px 8px !important',
    borderRadius: '4px !important',
  },
};

// This workaround is necessary in order to match the color of the tooltip arrow to the body
// GH issue and resolution found here: https://github.com/chakra-ui/chakra-ui/issues/4695#issuecomment-991023319
const $bg = cssVar('tooltip-bg');
const $arrowBg = cssVar('popper-arrow-bg');

export const TooltipConfig: StyleConfig = {
  baseStyle: {
    zIndex: 'tooltipExtra',
    color: 'text.onContrast',
    bg: $bg.reference,
    [$bg.variable]: 'colors.neutral.900',
    _dark: {
      [$bg.variable]: 'colors.neutral.50',
    },
    [$arrowBg.variable]: $bg.reference,
  },
};

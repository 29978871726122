import styled from 'styled-components';

export const RadioGroupWrapper = styled.div`
  &.disabled label {
    color: #a9b5b8;
  }

  &.disabled span {
    &::after {
      background-color: #a9b5b8;
    }
  }

  &.error label {
    color: #e01f1f;
  }

  &.error span {
    border: 1px solid #e01f1f;
  }
`;

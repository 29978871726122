import styled from 'styled-components';

export const CheckboxContainer = styled.div`
  display: inline-block;
  position: relative;
  vertical-align: middle;

  &.disabled div {
    background-color: #bcc5c8;
  }

  &.disabled span {
    color: #bcc5c8;
  }

  &.error div {
    border: 1px solid #e01f1f;
  }

  &.error span {
    color: 1px solid #e01f1f;
  }

  &.error a {
    color: 1px solid #e01f1f;
  }
`;

import styled from 'styled-components';

import { deriveMaxWidth } from '../utils';

interface IHeadingContainerProps {
  size: string;
}

export const HeadingContainer = styled.div<IHeadingContainerProps>`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 2rem;
  max-width: ${deriveMaxWidth};
  min-height: 2rem;
  z-index: 3000;
`;

import { ComponentStyleConfig } from '@chakra-ui/react';

export const AvatarConfig: ComponentStyleConfig = {
  baseStyle: {
    container: {
      backgroundColor: 'background.mostSubtleContrast',
      color: 'text.onContrast',
    },
  },
  sizes: {
    xs: {
      container: {
        height: '24px',
        width: '24px',
      },
      label: {
        fontSize: '10px',
        fontWeight: 'bold',
        pt: '1px',
        lineHeight: '24px',
      },
    },
    sm: {
      container: {
        height: '32px',
        width: '32px',
      },
      label: {
        fontSize: '14px',
        lineHeight: '24px',
      },
    },
    md: {
      container: {
        height: '36px',
        width: '36px',
      },
      label: {
        fontSize: '16px',
        pt: '1px',
        lineHeight: '24px',
      },
    },
    lg: {
      container: {
        height: '40px',
        width: '40px',
      },
      label: {
        fontSize: '16px',
        pt: '1px',
        lineHeight: '24px',
      },
    },
    xl: {
      container: {
        height: '64px',
        width: '64px',
      },
      label: {
        fontSize: '24px',
        pt: '1px',
        lineHeight: '24px',
      },
    },
  },
  variants: {
    default: {
      container: {
        backgroundColor: 'background.mostSubtleContrast',
        borderColor: 'background.mostSubtleContrast',
      },
    },
    accent: {
      container: {
        backgroundColor: 'text.accent',
        borderColor: 'text.accent',
      },
    },
    accentSubtle: {
      container: {
        backgroundColor: 'text.accentSubtle',
        borderColor: 'text.accentSubtle',
      },
    },
    contrast: {
      container: {
        backgroundColor: 'background.moreSubtleContrast',
        borderColor: 'background.moreSubtleContrast',
      },
    },
    outlineAccent: {
      container: {
        backgroundColor: 'background.default',
        color: 'text.accent',
        borderColor: 'text.accent',
        overflow: 'visible',
      },
    },
    outlineAccentSubtle: {
      container: {
        backgroundColor: 'background.default',
        color: 'text.accentSubtle',
        borderColor: 'text.accentSubtle',
        overflow: 'visible',
      },
    },
    outlineYellow: {
      container: {
        backgroundColor: 'background.warning',
        borderColor: 'border.warning',
        overflow: 'visible',
        color: 'iconDefault',
      },
    },
    dashed: {
      container: {
        backgroundColor: 'background.default',
        borderColor: 'transparent',
        overflow: 'visible',
        color: 'text.default',
        _after: {
          content: "''",
          position: 'absolute',
          top: '-2px',
          right: '-2px',
          bottom: '-2px',
          left: '-2px',
          border: `1px dashed`,
          borderColor: 'border.strong',
          borderRadius: '100%',
        },
      },
    },
  },
};

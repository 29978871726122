import styled from 'styled-components';

export const Label = styled.label`
  color: #354144;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  padding: 5px 10px 5px 0;
  position: relative;

  &.error {
    color: #e01f1f;

    span {
      border: 1px solid #e01f1f;
    }
  }
`;

import styled from 'styled-components';

export const Icon = styled.span`
  border: 1px solid #bcc5c8;
  border-radius: 50%;
  box-sizing: content-box;
  display: inline-block;
  height: 14px;
  left: 0;
  margin-right: 5px;
  position: relative;
  width: 14px;
  vertical-align: middle;

  &::after {
    background-color: #3ca0b4;
    border-radius: 50%;
    content: '';
    display: block;
    height: 0;
    left: 50%;
    opacity: 0;
    position: absolute;
    top: 50%;
    transition: all 110ms;
    width: 0;
  }
`;

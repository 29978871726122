import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

interface INavButtonProps {
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  children: ReactNode;
}

const NavButton = ({ children, onClick }: INavButtonProps): JSX.Element => {
  return (
    <Button type="button" onClick={onClick}>
      {children}
    </Button>
  );
};

NavButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default NavButton;

const Button = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  font-size: 1rem;

  &:focus {
    color: #3ca0b4;
    outline: none;
  }

  &:hover {
    color: #3ca0b4;
  }
`;

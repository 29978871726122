import styled from 'styled-components';
import { MonthType } from '@datepicker-react/hooks';

interface IGridContainerProps {
  activeMonths: MonthType[];
}

export const GridContainer = styled.div`
  display: grid;
  grid-gap: 0 64px;
  grid-template-columns: ${({ activeMonths }: IGridContainerProps): string => {
    return `repeat(${activeMonths.length}, 300px)`;
  }};
`;

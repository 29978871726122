/* eslint-disable @typescript-eslint/no-explicit-any */
// I wanted to use the never type, but jest was complaining and we had a time constraint
import React, { useState, useRef, ChangeEvent, FocusEvent, KeyboardEvent, InputHTMLAttributes, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Field } from '../TextInput/components/Field';
import { Input } from '../TextInput/components/Input';
import { Label } from '../TextInput/components/Label';
import { InputWrapper } from '../InputWrapper';
import MenuItem from './components/MenuItem';

interface IProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onSelect'> {
  name: string;
  label: string;
  className?: string;
  disabled?: boolean;
  value?: string | number;
  error?: string | undefined;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  items: any[];
  getItemValue: (item: any) => any;
  getItemDisplayValue?: (item: any) => any;
  onSelect: (value: any, item: any) => void;
  onFilterItems?: (item: any, search: string) => boolean;
  renderItem: (item: any, isSelected: boolean) => JSX.Element;
}

let ignoreBlur = false;

const AutoComplete = ({
  name,
  className,
  label,
  disabled,
  error,
  onChange: onChangeProp,
  value,
  items,
  getItemValue,
  getItemDisplayValue,
  onSelect: onSelectProp,
  onFilterItems,
  renderItem,
  ...rest
}: IProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const [cursorIndex, setCursorIndex] = useState(0);
  const [focussed, setFocus] = useState(false);
  const [filteredItems, setFilteredItems] = useState<any[]>(items);
  const itemRef = useRef<HTMLDivElement | null>(null);
  const menuRef = useRef<HTMLDivElement | null>(null);

  const disabledClass = disabled ? 'disabled' : '';
  const errorClass = error ? 'error' : '';
  const focusClass = focussed || error ? 'focussed' : '';
  const valueClass = value ? 'value' : '';

  useEffect(() => {
    setFilteredItems(items);
  }, [items, setFilteredItems]);

  const handleFocus = (e: FocusEvent<HTMLInputElement>): void => {
    setFocus(true);
    setIsOpen(true);
    const { onFocus } = { ...rest };
    if (onFocus) onFocus(e);
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>): void => {
    setFocus(false);
    if (!ignoreBlur) setIsOpen(false);
    const { onBlur } = { ...rest };
    if (onBlur) onBlur(e);
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (onChangeProp) onChangeProp(e);
    if (onFilterItems) {
      const filtered = items.filter((item) => {
        return onFilterItems(item, e.target.value);
      });
      setFilteredItems(filtered);
    }

    if (!isOpen) {
      setIsOpen(true);
    }

    if (e.target.value === '') {
      setFilteredItems([]);
    }
  };

  const onSelect = (item: any): void => {
    const e = {
      target: {
        value: getItemDisplayValue ? getItemDisplayValue(item) : '',
      },
    } as ChangeEvent<HTMLInputElement>;
    onChange(e);
    onSelectProp(getItemValue(item), item);
    setIsOpen(false);
    setIgnoreBlur(false);
    setCursorIndex(0);
  };

  const setIgnoreBlur = (ignore: boolean): void => {
    ignoreBlur = ignore;
  };

  const onKeyDown = (e: KeyboardEvent): void => {
    const selectedItem = itemRef?.current;
    const menu = menuRef?.current;

    if (e.keyCode === 38 && cursorIndex > 0) {
      setCursorIndex(cursorIndex - 1);

      if (selectedItem && menu) {
        const top = selectedItem.offsetTop - menu.scrollTop;
        menu.scrollBy({ top: -top, left: 0 });
      }
    }

    if (e.keyCode === 40 && cursorIndex < filteredItems.length - 1) {
      setCursorIndex(cursorIndex + 1);

      if (selectedItem && menu) {
        const top = selectedItem.offsetTop - menu.scrollTop;
        menu.scrollBy({ top: top, left: 0 });
      }
    }

    if (e.keyCode === 13 && isOpen) {
      e.preventDefault();
      onSelect(filteredItems[cursorIndex]);
    }
  };

  const renderItems = (): React.FunctionComponentElement<JSX.Element>[] => {
    return filteredItems.map((item, index) => {
      const element = renderItem(item, cursorIndex === index);
      return React.cloneElement(element, {
        key: index,
        className: cursorIndex === index ? 'selected' : '',
        ref: cursorIndex === index ? itemRef : null,
        onClick: () => onSelect(item),
      });
    });
  };

  return (
    <InputWrapper>
      <Field className={`${focusClass} ${errorClass} ${valueClass} ${disabledClass}`}>
        <Input
          name={name}
          className={className}
          disabled={disabled}
          placeholder={label}
          value={value}
          onChange={onChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={onKeyDown}
          autoComplete="off"
          aria-label={name}
          data-testid="autocomplete-input"
          {...rest}
        />
        <Label htmlFor={name}>{error || label}</Label>
      </Field>
      {filteredItems.length > 0 && isOpen && (
        <Menu
          ref={menuRef}
          className={className || ''}
          onTouchStart={(): void => setIgnoreBlur(true)}
          onMouseEnter={(): void => setIgnoreBlur(true)}
          onMouseLeave={(): void => setIgnoreBlur(false)}
        >
          <MenuContent>{renderItems()}</MenuContent>
        </Menu>
      )}
    </InputWrapper>
  );
};

AutoComplete.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string,
  onChange: PropTypes.func,
  items: PropTypes.array.isRequired,
  getItemValue: PropTypes.func.isRequired,
  getItemDisplayValue: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  onFilterItems: PropTypes.func,
  renderItem: PropTypes.func.isRequired,
};

export default AutoComplete;
export { MenuItem };

const Menu = styled.div`
  background-color: #f9f9fa;
  border: #dde2e3;
  border-radius: 4px;
  box-sizing: border-box;
  max-height: 500px;
  overflow: hidden;
  overflow-y: scroll;
  position: absolute;
  top: 51px;
  transition: 0.3s all;
  width: 100%;
  z-index: 1000;

  &::-webkit-scrollbar {
    height: 0;
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    margin: 5px 0;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    box-shadow: rgba(255, 255, 255, 0.3) 0 0 0 1px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.35);
  }
`;

const MenuContent = styled.div`
  box-sizing: border-box;
  width: 100%;
`;

import React, { useState, useContext, useRef, useEffect, useCallback, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { IModalContext } from './interfaces/IModalContext';
import { IModal } from './interfaces/IModal';
import ModalContext from './contexts/ModalContext';
import { ModalSizes } from './enums/ModalSizes';
import Modal from './components/Modal';

export const useModal = (): IModalContext => useContext<IModalContext>(ModalContext);
export { ModalSizes };
export { Modal };

interface IModalProviderProps {
  children: ReactNode;
}

const ModalProvider = ({ children }: IModalProviderProps): JSX.Element => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [context, setContext] = useState<HTMLDivElement | null>();
  const [modal, setModal] = useState<IModal>({ isOpen: false, name: null, data: null });

  const openModal = useCallback((name: string, data?: unknown) => {
    setModal({ isOpen: true, name, data: data || null });
  }, []);

  const closeModal = useCallback(() => {
    setModal({ isOpen: false, name: null, data: null });
  }, []);

  useEffect(() => {
    setContext(modalRef.current);
  }, []);

  return (
    <>
      <ModalContext.Provider
        value={{
          modal,
          openModal,
          closeModal,
          modalNode: context,
        }}
      >
        {children}
      </ModalContext.Provider>
      <div ref={modalRef} />
    </>
  );
};

ModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

ModalProvider.displayName = 'ModalProvider';

export default ModalProvider;

import React, { useContext, ReactPortal, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import ModalContext from '../contexts/ModalContext';
import { IModalContext } from '../interfaces/IModalContext';
import { ModalSizes } from '../enums/ModalSizes';
import { ModalOverlay } from './ModalOverlay';
import { ModalContainer } from './ModalContainer';
import { HeadingContainer } from './HeadingContainer';
import { ModalExitButton } from './ModalExitButton';
import { ModalContent } from './ModalContent';

interface IModalProps {
  children: ReactNode;
  size?: ModalSizes;
  disableBackdropClick?: boolean;
  name?: string;
  overlayOpacity?: number;
  includeCloseButton?: boolean;
}

const Modal = ({
  children,
  size = ModalSizes.md,
  disableBackdropClick,
  name,
  overlayOpacity = 0.7,
  includeCloseButton = true,
}: IModalProps): ReactPortal | null => {
  const { modalNode, modal, closeModal } = useContext<IModalContext>(ModalContext);
  const { isOpen, name: modalName } = modal;

  return modalNode && isOpen && modalName === name
    ? createPortal(
        <ModalOverlay overlayOpacity={overlayOpacity}>
          <HeadingContainer size={size}>
            {includeCloseButton && <ModalExitButton onClick={closeModal} />}
          </HeadingContainer>

          <ModalContainer
            size={size}
            isOpen={isOpen}
            closeModal={closeModal}
            disableBackdropClick={disableBackdropClick}
            data-testid="modal-container"
          >
            <ModalContent data-testid="modal-content">{children}</ModalContent>
          </ModalContainer>
        </ModalOverlay>,
        modalNode,
      )
    : null;
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf([ModalSizes.xs, ModalSizes.sm, ModalSizes.md, ModalSizes.lg]),
  name: PropTypes.string.isRequired,
};

Modal.displayName = 'Modal';

export default Modal;

import React, { useState, ChangeEvent, FocusEvent, InputHTMLAttributes } from 'react';
import PropTypes from 'prop-types';
import { ValidationOptions } from 'react-hook-form';
import { Field } from './components/Field';
import { Input } from './components/Input';
import { Label } from './components/Label';
import { InputWrapper } from '../InputWrapper';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
  className?: string;
  disabled?: boolean;
  value?: string | number;
  error?: string | undefined;
  validation?: ValidationOptions;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  register?: (e: unknown, v: unknown) => void;
}

const TextInput = ({
  name,
  className,
  label,
  disabled,
  register,
  validation,
  error,
  onChange,
  value,
  ...rest
}: IProps): JSX.Element => {
  const [focussed, setFocus] = useState(false);
  const [internalRefVal, setInternalRefVal] = useState<string | number | null>(null);

  const disabledClass = disabled ? 'disabled' : '';
  const errorClass = error ? 'error' : '';
  const focusClass = focussed || error ? 'focussed' : '';
  const valueClass = internalRefVal || value ? 'value' : '';

  if (!onChange && !register) {
    throw new Error('Please provide react-hook-form register() or and onChange function and value!!!');
  }

  const handleFocus = (e: FocusEvent<HTMLInputElement>): void => {
    setFocus(true);
    const { onFocus } = { ...rest };
    if (onFocus) onFocus(e);
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>): void => {
    setFocus(false);
    const { onBlur } = { ...rest };
    if (onBlur) onBlur(e);
  };

  return (
    <InputWrapper>
      <Field className={`${focusClass} ${errorClass} ${valueClass} ${disabledClass}`}>
        <Input
          name={name}
          className={className}
          disabled={disabled}
          placeholder={label}
          ref={(e): void => {
            if (register) {
              register(e, validation);
              if (e?.value) {
                setInternalRefVal(e.value);
              }
            }
          }}
          value={value}
          onChange={onChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          autoComplete="off"
          aria-label={name}
          data-testid="text-input"
          {...rest}
        />
        <Label htmlFor={name}>{error || label}</Label>
      </Field>
    </InputWrapper>
  );
};

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string,
  validation: PropTypes.object,
  onChange: PropTypes.func,
  register: PropTypes.func,
};

TextInput.displayName = 'TextInput';

export default TextInput;

import React, { ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import { ValidationOptions } from 'react-hook-form';
import styled from 'styled-components';

import { InputWrapper } from '../InputWrapper';

interface IProps {
  name: string;
  label: string;
  className?: string;
  disabled?: boolean;
  value?: string | number;
  error?: string | undefined;
  validation?: ValidationOptions;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  register?: (e: unknown, v: unknown) => void;
}

const TextArea = ({
  name,
  label,
  className,
  disabled,
  value,
  error,
  validation,
  onChange,
  register,
  ...rest
}: IProps): JSX.Element => {
  const errorClass = error ? 'error' : '';
  return (
    <InputWrapper>
      <StyledTextArea
        name={name}
        placeholder={label}
        disabled={disabled}
        ref={(e): void => {
          if (register) {
            register(e, validation);
          }
        }}
        value={value}
        onChange={onChange}
        className={`${className} ${errorClass}`}
        autoComplete="off"
        aria-label={name}
        data-testid="text-area"
        {...rest}
      />
    </InputWrapper>
  );
};

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string,
  validation: PropTypes.object,
  onChange: PropTypes.func,
  register: PropTypes.func,
};

TextArea.displayName = 'TextArea';

export default TextArea;

const StyledTextArea = styled.textarea`
  background-color: #f9f9fa;
  border: solid 1px #dde2e3;
  border-radius: 4px;
  box-sizing: border-box;
  color: #354144;
  font-family: inherit;
  font-size: 0.875rem;
  font-weight: 400;
  min-height: 150px;
  line-height: normal;
  padding: 1rem;
  position: relative;
  resize: vertical;
  transition: 0.3s all;
  width: 100%;

  :disabled {
    background-color: #bcc5c8;
  }

  :focus {
    border: 1px solid #3ca0b4;
    outline: none;
  }

  &.error {
    border: 2px solid #e01f1f;
  }
`;

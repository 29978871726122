import React, { ReactNode, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { deriveMaxWidth, containsClick } from '../utils';
import { ModalSizes } from '../enums/ModalSizes';

interface IModalContainerProps {
  children: ReactNode;
  size: ModalSizes;
  isOpen: boolean;
  closeModal: () => void;
  disableBackdropClick?: boolean;
}

const ModalContainer = ({
  children,
  size = ModalSizes.md,
  isOpen,
  closeModal,
  disableBackdropClick,
}: IModalContainerProps): JSX.Element => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClick = (e: MouseEvent): void => {
      // You clicked in the modal, so don't close
      if ([ref].some(containsClick(e))) return;

      if (isOpen && !disableBackdropClick) closeModal();
    };
    document.addEventListener('mousedown', handleClick);

    return (): void => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [ref]);

  return (
    <StyledContainer ref={ref} size={size}>
      {children}
    </StyledContainer>
  );
};

ModalContainer.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf([ModalSizes.xs, ModalSizes.sm, ModalSizes.md, ModalSizes.lg]),
};

export { ModalContainer };

interface IStyledContainerProps {
  size: ModalSizes;
}

const StyledContainer = styled.div<IStyledContainerProps>`
  width: 100%;
  max-width: ${deriveMaxWidth};
  margin: 0 0 2rem 0;
  background-color: #f9f9fa;
  border-radius: 0.625rem;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  overflow-y: scroll;
  position: relative;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    height: 0;
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    margin: 5px 0;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    box-shadow: rgba(255, 255, 255, 0.3) 0 0 0 1px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.35);
  }
`;

import { CoreColors, SemanticColorToken } from './color.types';

export const corePalette: CoreColors = {
  primary: {
    50: '#FAFAFF',
    100: '#E8E7FE',
    200: '#C1C0FC',
    300: '#9B98FB',
    400: '#807CF9',
    500: '#554EF6',
    600: '#3B2FF3',
    700: '#2112C2',
    800: '#14098A',
    900: '#090458',
  },
  secondary: {
    50: '#EFFDFF',
    100: '#E4FAF6',
    200: '#B4EEEE',
    300: '#89D3D8',
    400: '#35B2BB',
    500: '#2B959C',
    600: '#227B81',
    700: '#186064',
    800: '#0F4447',
    900: '#072E30',
  },
  neutral: {
    50: '#FFFFFF',
    100: '#F7F7FA',
    200: '#DCDBE2',
    300: '#C4C4CE',
    400: '#A1A0B1',
    500: '#8A889E',
    600: '#6E6D87',
    700: '#555467',
    800: '#434253',
    900: '#363546',
  },
  danger: {
    50: '#FFF9F9',
    100: '#FFEDED',
    200: '#FFC9C1',
    300: '#FC8F81',
    400: '#F96C59',
    500: '#CB3D1D',
    600: '#A02E14',
    700: '#731F0B',
    800: '#511305',
    900: '#310802',
  },
  warning: {
    50: '#FFFDFA',
    100: '#FFF7EB',
    200: '#FEE8C3',
    300: '#F6BE5E',
    400: '#ECB14B',
    500: '#D69E2E',
    600: '#B7791F',
    700: '#975A16',
    800: '#744210',
    900: '#5F370E',
  },
  success: {
    50: '#F0FFF4',
    100: '#C6F6D5',
    200: '#9AE6B4',
    300: '#68D391',
    400: '#48BB78',
    500: '#38A169',
    600: '#25855A',
    700: '#276749',
    800: '#22543D',
    900: '#1C4532',
  },
  primaryAlpha: {
    50: '#554EF60A',
    100: '#554EF60F',
    200: '#554EF614',
    300: '#554EF629',
    400: '#554EF63D',
    500: '#554EF65C',
    600: '#554EF67A',
    700: '#554EF69E',
    800: '#554EF6CC',
    900: '#554EF6EB',
  },
  primaryInverseAlpha: {
    50: '#C1C0FC0A',
    100: '#C1C0FC0F',
    200: '#C1C0FC14',
    300: '#C1C0FC29',
    400: '#C1C0FC3D',
    500: '#C1C0FC5C',
    600: '#C1C0FC7A',
    700: '#C1C0FC9E',
    800: '#C1C0FCCC',
    900: '#C1C0FCEB',
  },
  blackAlpha: {
    50: '#3635460A',
    100: '#3635460F',
    200: '#36354614',
    300: '#36354629',
    400: '#3635463D',
    500: '#3635465C',
    600: '#3635467A',
    700: '#3635469E',
    800: '#363546CC',
    900: '#363546EB',
  },
  whiteAlpha: {
    50: '#FFFFFF0A',
    100: '#FFFFFF0F',
    200: '#FFFFFF14',
    300: '#FFFFFF29',
    400: '#FFFFFF3D',
    500: '#FFFFFF5C',
    600: '#FFFFFF7A',
    700: '#FFFFFF9E',
    800: '#FFFFFFCC',
    900: '#FFFFFFEB',
  },
  dangerAlpha: {
    50: '#CB3D1D0A',
    100: '#CB3D1D0F',
    200: '#CB3D1D14',
    300: '#CB3D1D29',
    400: '#CB3D1D3D',
    500: '#CB3D1D5C',
    600: '#CB3D1D7A',
    700: '#CB3D1D9E',
    800: '#CB3D1DCC',
    900: '#CB3D1DEB',
  },
  dangerInverseAlpha: {
    50: '#FC8F810A',
    100: '#FC8F810F',
    200: '#FC8F8114',
    300: '#FC8F8129',
    400: '#FC8F813D',
    500: '#FC8F815C',
    600: '#FC8F817A',
    700: '#FC8F819E',
    800: '#FC8F81CC',
    900: '#FC8F81EB',
  },
};

const aliasMap = {
  primary: {
    default: {
      default: corePalette.primary['500'],
      _dark: corePalette.primary['200'],
    },
    hover: {
      default: corePalette.primary['700'],
      _dark: corePalette.primary['100'],
    },
    pressed: {
      default: corePalette.primary['800'],
      _dark: corePalette.primary['50'],
    },
    inverse: {
      default: corePalette.primary['200'],
      _dark: corePalette.primary['500'],
    },
    inverseHover: {
      default: corePalette.primary['100'],
      _dark: corePalette.primary['700'],
    },
    inversePressed: {
      default: corePalette.primary['50'],
      _dark: corePalette.primary['800'],
    },
    subtle: {
      default: corePalette.primary['100'],
      _dark: corePalette.neutral['700'],
    },
  },
  secondary: {
    default: {
      default: corePalette.secondary['500'],
      _dark: corePalette.secondary['200'],
    },
    subtle: {
      default: corePalette.secondary['300'],
      _dark: corePalette.secondary['400'],
    },
  },
  danger: {
    default: {
      default: corePalette.danger['500'],
      _dark: corePalette.danger['300'],
    },
    hover: {
      default: corePalette.danger['600'],
      _dark: corePalette.danger['200'],
    },
    pressed: {
      default: corePalette.danger['700'],
      _dark: corePalette.danger['100'],
    },
    inverse: {
      default: corePalette.danger['300'],
      _dark: corePalette.danger['500'],
    },
    inverseHover: {
      default: corePalette.danger['200'],
      _dark: corePalette.danger['600'],
    },
    inversePressed: {
      default: corePalette.danger['100'],
      _dark: corePalette.danger['700'],
    },
    subtle: {
      default: corePalette.danger['200'],
      _dark: corePalette.danger['600'],
    },
  },
  warning: {
    default: {
      default: corePalette.warning['500'],
      _dark: corePalette.warning['400'],
    },
    subtle: {
      default: corePalette.warning['200'],
      _dark: corePalette.warning['700'],
    },
    note: {
      default: corePalette.warning['100'],
      _dark: corePalette.warning['800'],
    },
  },
  success: {
    default: {
      default: corePalette.success['600'],
      _dark: corePalette.success['300'],
    },
    subtle: {
      default: corePalette.success['100'],
      _dark: corePalette.success['700'],
    },
  },
  neutral: {
    light: {
      default: corePalette.neutral['50'],
      _dark: corePalette.neutral['900'],
    },
    subtle1: {
      default: corePalette.neutral['100'],
      _dark: corePalette.neutral['800'],
    },
    subtle2: {
      default: corePalette.neutral['200'],
      _dark: corePalette.neutral['700'],
    },
    subtle3: {
      default: corePalette.neutral['300'],
      _dark: corePalette.neutral['600'],
    },
    subtle4: {
      default: corePalette.neutral['600'],
      _dark: corePalette.neutral['400'],
    },
    dark: {
      default: corePalette.neutral['900'],
      _dark: corePalette.neutral['50'],
    },
    overlay: {
      default: corePalette.neutral['50'],
      _dark: corePalette.neutral['800'],
    },
    backdrop: {
      default: corePalette.blackAlpha['600'],
      _dark: corePalette.blackAlpha['600'],
    },
    subtle1Inverse: {
      default: corePalette.neutral['800'],
      _dark: corePalette.neutral['100'],
    },
    subtle2Inverse: {
      default: corePalette.neutral['700'],
      _dark: corePalette.neutral['200'],
    },
    subtle3Inverse: {
      default: corePalette.neutral['600'],
      _dark: corePalette.neutral['300'],
    },
    subtle4Inverse: {
      default: corePalette.neutral['400'],
      _dark: corePalette.neutral['600'],
    },
  },
  primaryAlpha: {
    default: {
      default: corePalette.primaryAlpha['50'],
      _dark: corePalette.primaryInverseAlpha['300'],
    },
    hover: {
      default: corePalette.primaryAlpha['100'],
      _dark: corePalette.primaryInverseAlpha['400'],
    },
    pressed: {
      default: corePalette.primaryAlpha['200'],
      _dark: corePalette.primaryInverseAlpha['500'],
    },
    inverse: {
      default: corePalette.primaryInverseAlpha['300'],
      _dark: corePalette.primaryAlpha['50'],
    },
    inverseHover: {
      default: corePalette.primaryInverseAlpha['400'],
      _dark: corePalette.primaryAlpha['100'],
    },
    inversePressed: {
      default: corePalette.primaryInverseAlpha['500'],
      _dark: corePalette.primaryAlpha['200'],
    },
  },
  dangerAlpha: {
    default: {
      default: corePalette.dangerAlpha['50'],
      _dark: corePalette.dangerInverseAlpha['300'],
    },
    hover: {
      default: corePalette.dangerAlpha['100'],
      _dark: corePalette.dangerInverseAlpha['400'],
    },
    pressed: {
      default: corePalette.dangerAlpha['200'],
      _dark: corePalette.dangerInverseAlpha['500'],
    },
    inverse: {
      default: corePalette.dangerInverseAlpha['300'],
      _dark: corePalette.dangerAlpha['50'],
    },
    inverseHover: {
      default: corePalette.dangerInverseAlpha['400'],
      _dark: corePalette.dangerAlpha['100'],
    },
    inversePressed: {
      default: corePalette.dangerInverseAlpha['500'],
      _dark: corePalette.dangerAlpha['200'],
    },
  },
  neutralAlpha: {
    default: {
      default: corePalette.blackAlpha['50'],
      _dark: corePalette.whiteAlpha['300'],
    },
    hover: {
      default: corePalette.blackAlpha['100'],
      _dark: corePalette.whiteAlpha['400'],
    },
    pressed: {
      default: corePalette.blackAlpha['200'],
      _dark: corePalette.whiteAlpha['500'],
    },
    dark: {
      default: corePalette.blackAlpha['500'],
      _dark: corePalette.whiteAlpha['500'],
    },
    inverse: {
      default: corePalette.whiteAlpha['300'],
      _dark: corePalette.blackAlpha['50'],
    },
    inverseHover: {
      default: corePalette.whiteAlpha['400'],
      _dark: corePalette.blackAlpha['100'],
    },
    inversePressed: {
      default: corePalette.whiteAlpha['500'],
      _dark: corePalette.blackAlpha['200'],
    },
    inverseDark: {
      default: corePalette.whiteAlpha['500'],
      _dark: corePalette.blackAlpha['500'],
    },
  },
};

// Once we upgrade Chakra to < v2.6 we can nest these
export const semanticColorTokens: Record<SemanticColorToken, Record<'default' | '_dark', string>> = {
  // token structure = [property][variant][alpha?][contrast?][state?]
  'text.default': aliasMap.neutral.dark,
  'text.subtle': aliasMap.neutral.subtle4,
  'text.interactive': aliasMap.primary.default,
  'text.interactive.hover': aliasMap.primary.hover,
  'text.info': aliasMap.primary.default,
  'text.success': aliasMap.success.default,
  'text.warning': aliasMap.warning.default,
  'text.danger': aliasMap.danger.default,
  'text.placeholder': aliasMap.neutralAlpha.dark,
  'text.accent': aliasMap.secondary.default,
  'text.accentSubtle': aliasMap.secondary.subtle,
  'text.onContrast': aliasMap.neutral.light,
  'text.subtleOnContrast': aliasMap.neutral.subtle4Inverse,
  'text.interactiveOnContrast': aliasMap.primary.inverse,
  'text.interactiveOnContrast.hover': aliasMap.primary.inverseHover,
  'text.placeholderOnContrast': aliasMap.neutralAlpha.inverseDark,
  'text.dangerOnContrast': aliasMap.danger.inverse,
  // backgrounds
  'background.default': aliasMap.neutral.light,
  'background.subtle': aliasMap.neutral.subtle1,
  'background.lessSubtle': aliasMap.neutral.subtle2,
  'background.primary': aliasMap.primary.default,
  'background.primary.hover': aliasMap.primary.hover,
  'background.primary.pressed': aliasMap.primary.pressed,
  'background.info': aliasMap.primary.subtle,
  'background.success': aliasMap.success.subtle,
  'background.warning': aliasMap.warning.subtle,
  'background.dangerSubtle': aliasMap.danger.subtle,
  'background.danger': aliasMap.danger.default,
  'background.danger.hover': aliasMap.danger.hover,
  'background.danger.pressed': aliasMap.danger.pressed,
  'background.accent': aliasMap.secondary.default,
  'background.accentSubtle': aliasMap.secondary.subtle,
  'background.note': aliasMap.warning.note,
  'background.alpha': aliasMap.neutralAlpha.default,
  'background.alpha.hover': aliasMap.neutralAlpha.hover,
  'background.alpha.pressed': aliasMap.neutralAlpha.pressed,
  'background.primaryAlpha': aliasMap.primaryAlpha.default,
  'background.primaryAlpha.hover': aliasMap.primaryAlpha.hover,
  'background.primaryAlpha.pressed': aliasMap.primaryAlpha.pressed,
  'background.dangerAlpha': aliasMap.dangerAlpha.default,
  'background.dangerAlpha.hover': aliasMap.dangerAlpha.hover,
  'background.dangerAlpha.pressed': aliasMap.dangerAlpha.pressed,
  'background.overlay': aliasMap.neutral.overlay,
  'background.backdrop': aliasMap.neutral.backdrop,
  'background.contrast': aliasMap.neutral.dark,
  'background.subtleContrast': aliasMap.neutral.subtle1Inverse,
  'background.moreSubtleContrast': aliasMap.neutral.subtle2Inverse,
  'background.mostSubtleContrast': aliasMap.neutral.subtle3,
  'background.primaryContrast': aliasMap.primary.inverse,
  'background.primaryContrast.hover': aliasMap.primary.inverseHover,
  'background.primaryContrast.pressed': aliasMap.primary.inversePressed,
  'background.successContrast': aliasMap.success.default,
  'background.warningContrast': aliasMap.warning.default,
  'background.dangerSubtleContrast': aliasMap.danger.default,
  'background.dangerContrast': aliasMap.danger.inverse,
  'background.dangerContrast.hover': aliasMap.danger.inverseHover,
  'background.dangerContrast.pressed': aliasMap.danger.inversePressed,
  'background.alphaContrast': aliasMap.neutralAlpha.inverse,
  'background.alphaContrast.hover': aliasMap.neutralAlpha.inverseHover,
  'background.alphaContrast.pressed': aliasMap.neutralAlpha.inversePressed,
  'background.primaryAlphaContrast': aliasMap.primaryAlpha.inverse,
  'background.primaryAlphaContrast.hover': aliasMap.primaryAlpha.inverseHover,
  'background.primaryAlphaContrast.pressed': aliasMap.primaryAlpha.inversePressed,
  'background.dangerAlphaContrast': aliasMap.dangerAlpha.inverse,
  'background.dangerAlphaContrast.hover': aliasMap.dangerAlpha.inverseHover,
  'background.dangerAlphaContrast.pressed': aliasMap.dangerAlpha.inversePressed,
  // borders
  'divider.default': aliasMap.neutral.subtle2,
  'border.default': aliasMap.neutral.subtle2,
  'border.subtle': aliasMap.neutral.subtle4,
  'border.strong': aliasMap.neutral.dark,
  'border.focus': aliasMap.primary.default,
  'border.info': aliasMap.primary.default,
  'border.success': aliasMap.success.default,
  'border.warning': aliasMap.warning.default,
  'border.danger': aliasMap.danger.default,
  'border.accent': aliasMap.secondary.default,
  'border.accentSubtle': aliasMap.secondary.subtle,
  'border.contrast': aliasMap.neutral.subtle2Inverse,
  'border.subtleContrast': aliasMap.neutral.subtle4Inverse,
  'border.focusContrast': aliasMap.primary.inverse,
  'border.strongContrast': aliasMap.neutral.light,
  'border.dangerContrast': aliasMap.danger.inverse,
};

import React, { useRef, useContext } from 'react';
import styled from 'styled-components';
import { useDay } from '@datepicker-react/hooks';
import { DatePickerContext } from '../contexts/DatePickerContext';

interface IDayProps {
  dayLabel: string;
  date: Date;
  onDaySelected: () => void;
}

const Day = ({ dayLabel, date, onDaySelected }: IDayProps): JSX.Element => {
  const dayRef = useRef(null);
  const {
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateSelect,
    onDateFocus,
    onDateHover,
  } = useContext(DatePickerContext);

  const {
    isSelected,
    isSelectedStartOrEnd,
    isWithinHoverRange,
    disabledDate,
    onClick,
    onKeyDown,
    onMouseEnter,
    tabIndex,
  } = useDay({
    date,
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateFocus,
    onDateSelect,
    onDateHover,
    dayRef,
  });

  if (!dayLabel) {
    return <div />;
  }

  const onSelectDay = (): void => {
    onClick();
    onDaySelected();
  };

  return (
    <DayButton
      onClick={onSelectDay}
      onKeyDown={onKeyDown}
      onMouseEnter={onMouseEnter}
      tabIndex={tabIndex}
      type="button"
      ref={dayRef}
      isSelectedStartOrEnd={isSelectedStartOrEnd}
      isSelected={isSelected}
      isWithinHoverRange={isWithinHoverRange}
      isDisabled={disabledDate}
    >
      {dayLabel}
    </DayButton>
  );
};

export default Day;

interface IDayButtonProps {
  isSelectedStartOrEnd: boolean;
  isSelected: boolean;
  isWithinHoverRange: boolean;
  isDisabled: boolean;
}

const DayButton = styled.button<IDayButtonProps>`
  border: 0;
  background-color: ${({
    isSelectedStartOrEnd,
    isSelected,
    isWithinHoverRange,
    isDisabled,
  }: IDayButtonProps): string => {
    if (isSelectedStartOrEnd) {
      return '#78CBDC';
    } else if (isSelected) {
      return '#78CBDC';
    } else if (isWithinHoverRange) {
      return '#78CBDC';
    } else if (isDisabled) {
      return '#78CBDC';
    } else {
      return '#F9F9FA';
    }
  }};
  cursor: pointer;
  color: black;
  padding: 8px;

  &:hover {
    background-color: #78cbdc;
  }

  &:focus {
    outline: none;
  }
`;

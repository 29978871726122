export const fonts: Fonts = {
  heading: `'Nunito', sans-serif`,
  body: `'Roboto', sans-serif`,
  mono: 'LinearSans, Noto Sans, Arial, Helvetica, sans-serif',
};

export type Fonts = {
  heading: string;
  body: string;
  mono: string;
};

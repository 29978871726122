import React, { useCallback, useState, useContext, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { ISnackbarContext } from './interfaces/ISnackbarContext';
import { ISnackbar } from './interfaces/ISnackbar';
import SnackbarContext from './contexts/SnackbarContext';
import SnackbarBucket from './components/SnackbarBucket';

export { MessageTypes } from './enums/MessageTypes';
export const useSnackbar = (): ISnackbarContext => useContext<ISnackbarContext>(SnackbarContext);

interface IProps {
  children: ReactNode;
}

const SnackbarProvider = ({ children }: IProps): JSX.Element => {
  const [snackbars, setSnackbars] = useState<ISnackbar[]>([]);

  const addSnackbar = useCallback(
    (snackbar: ISnackbar) => {
      const id = Date.now();
      setSnackbars((currentSnackbars) => {
        const newSnackbars = [...currentSnackbars];
        newSnackbars.unshift({ id, ...snackbar });
        return newSnackbars;
      });
    },
    [setSnackbars],
  );

  const removeSnackbar = useCallback(
    (id: number) => {
      setSnackbars((currentSnackbars) => currentSnackbars.filter((snackbar) => snackbar.id !== id));
    },
    [setSnackbars],
  );

  return (
    <SnackbarContext.Provider
      value={{
        snackbars,
        addSnackbar,
        removeSnackbar,
      }}
    >
      <SnackbarBucket snackbars={snackbars} removeSnackbar={removeSnackbar} />
      {children}
    </SnackbarContext.Provider>
  );
};

SnackbarProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

SnackbarProvider.displayName = 'SnackbarProvider';

export default SnackbarProvider;

import styled from 'styled-components';

export const Label = styled.label`
  color: #f9f9fa;
  font-size: 12px;
  font-weight: normal;
  left: 16px;
  line-height: 24px;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 24px;
  transition: 0.1s all ease-in-out;

  .error {
    color: #e01f1f;
  }
`;

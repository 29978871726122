import styled from 'styled-components';
import { HiddenCheckbox } from './HiddenCheckbox';
import { Icon } from './Icon';

interface IStyledCheckboxProps {
  checked: boolean;
}

export const StyledCheckbox = styled.div`
  background: ${({ checked }: IStyledCheckboxProps): string => (checked ? '#3CA0B4' : '#F9F9FA')};
  border: 1px solid #bcc5c8;
  border-radius: 3px;
  box-sizing: content-box;
  display: inline-block;
  height: 16px;
  transition: all 150ms;
  width: 16px;

  ${HiddenCheckbox}:hover + & {
    border: 1px solid #3ca0b4;
  }

  ${Icon} {
    visibility: ${(props: IStyledCheckboxProps): string => (props.checked ? 'visible' : 'hidden')};
  }
`;

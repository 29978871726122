import { memo } from 'react';
import styled from 'styled-components';

const SnackbarCloseButton = memo(styled.button`
  background-color: inherit;
  border: none;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  font-size: 2em;
  font-weight: bold;
  margin: 0;
  margin-left: auto;
  outline: none;
  padding: 0;
`);

export default SnackbarCloseButton;

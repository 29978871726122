import styled from 'styled-components';

interface IModalOverlayProps {
  overlayOpacity: number;
}

export const ModalOverlay = styled.div<IModalOverlayProps>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1299;

  background-color: ${({ overlayOpacity }: IModalOverlayProps): string => `rgba(0, 0, 0, ${overlayOpacity})`};
  transition: visibility 0.3s ease-in, opacity 0.3s ease-in;
`;

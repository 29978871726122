import styled from 'styled-components';

export const HiddenCheckbox = styled.input`
  border: 0;
  cursor: pointer;
  height: 16px;
  margin: -1px;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 20px;
`;

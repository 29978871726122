import React, { memo, ReactNode, MouseEvent, FocusEvent } from 'react';
import PropTypes from 'prop-types';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { ButtonTypes } from './enum/ButtonTypes';
import { IButtonTheme } from './interfaces/IButtonTheme';
import BounceLoader from '../BounceLoader';

interface IProps {
  children: ReactNode;
  buttonType?: ButtonTypes.Primary | ButtonTypes.Secondary | ButtonTypes.Text | ButtonTypes.Ghost;
  fullWidth?: boolean;
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  disabled?: boolean;
  isLoading?: boolean;
  onClick?: (e: MouseEvent) => void;
  onFocus?: (e: FocusEvent) => void;
  onBlur?: (e: FocusEvent) => void;
}

const Button = ({
  children,
  buttonType,
  fullWidth,
  type,
  className,
  disabled,
  isLoading,
  onClick,
  onFocus,
  onBlur,
  ...rest
}: IProps): JSX.Element => {
  const loadingClass = isLoading ? 'loading' : '';
  return (
    <StyledButton
      buttonType={buttonType || ButtonTypes.Primary}
      fullWidth={fullWidth}
      type={type}
      className={`${className} ${loadingClass}`}
      disabled={disabled}
      isLoading={isLoading}
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}
      data-testid="button"
      {...rest}
    >
      {isLoading ? <BounceLoader loading={isLoading} /> : children}
    </StyledButton>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  buttonType: PropTypes.oneOf([ButtonTypes.Primary, ButtonTypes.Secondary, ButtonTypes.Text, ButtonTypes.Ghost]),
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

Button.displayName = 'Button';

export default memo(Button);

export { ButtonTypes };

const StyledButton = styled.button<IProps>`
  align-items: center;
  background-color: #3ca0b4;
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  height: 44px;
  border-radius: 0.3125rem;
  box-sizing: border-box;
  color: #f9f9fa;
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 400;
  margin-bottom: 1rem;
  min-width: ${({ fullWidth }): string => (fullWidth ? 'auto' : '8rem')};
  padding-left: 0.75rem;
  padding-top: ${({ isLoading }: IButtonTheme): string => (isLoading ? '0.5rem' : '0.75rem')};
  padding-right: 0.75rem;
  padding-bottom: 0.75rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  top: 0;
  width: ${({ fullWidth }: IButtonTheme): string => (fullWidth ? '100%' : 'auto')};
  transition: all 0.2s;
  .spinner {
    margin-top: 0.125rem;
    background-color: ${({ buttonType }): string => (buttonType === ButtonTypes.Primary ? '#F9F9FA' : '#3CA0B4')};
  }
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  ${({ buttonType }: IButtonTheme): FlattenSimpleInterpolation | undefined => {
    if (buttonType === ButtonTypes.Secondary) {
      return css`
        background-color: #f9f9fa;
        border: 1px solid #3ca0b4;
        color: #3ca0b4;
        &:hover:not(.loading):not([disabled]),
        &:focus:not(.loading):not([disabled]) {
          background-color: #236976;
          border-color: #236976;
          color: #f9f9fa;
        }
      `;
    }
  }};
  ${({ buttonType }: IButtonTheme): FlattenSimpleInterpolation | undefined => {
    if (buttonType === ButtonTypes.Text) {
      return css`
        background-color: transparent;
        border: none;
        border-color: none;
        box-shadow: none;
        color: #3ca0b4;
        outline: none;
        &:hover:not(.loading):not([disabled]),
        &:focus:not(.loading):not([disabled]) {
          background-color: transparent;
          border: none;
          border-color: none;
          color: #236976;
          outline: none;
        }
      `;
    }
  }};
  ${({ buttonType }: IButtonTheme): FlattenSimpleInterpolation | undefined => {
    if (buttonType === ButtonTypes.Ghost) {
      return css`
        background-color: transparent;
        border: 1px solid #3ca0b4;
        color: #3ca0b4;
        &:hover:not(.loading):not([disabled]),
        &:focus:not(.loading):not([disabled]) {
          background-color: #3ca0b4;
          border: 1px solid #3ca0b4;
          color: #f9f9fa;
          opacity: 1;
        }
      `;
    }
  }};
`;

import React from 'react';
import SnackbarContainer from './SnackbarContainer';
import Snackbar from './Snackbar';
import { ISnackbar } from '../interfaces/ISnackbar';

type Props = {
  snackbars: ISnackbar[];
  removeSnackbar: (id: number) => void;
};

const SnackbarBucket = ({ snackbars = [], removeSnackbar }: Props): JSX.Element => {
  return (
    <SnackbarContainer>
      {snackbars.map((snackbar) => {
        return (
          <Snackbar
            key={snackbar.id}
            id={snackbar.id || Date.now()}
            type={snackbar.type}
            timeout={snackbar.timeout}
            removeSnackbar={removeSnackbar}
          >
            {snackbar.message}
          </Snackbar>
        );
      })}
    </SnackbarContainer>
  );
};

export default SnackbarBucket;

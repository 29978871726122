import { memo } from 'react';
import styled from 'styled-components';

import { MessageTypes } from '../enums/MessageTypes';

const deriveBackgroundColor = (type: MessageTypes): string => {
  if (type === MessageTypes.Success) {
    return '#25855A';
  }

  if (type === MessageTypes.Error) {
    return '#E01F1F';
  }

  return '#5B7074';
};

type Props = {
  type: MessageTypes;
};

const SnackbarContent = memo(styled.div`
  align-items: center;
  background-color: ${({ type }: Props): string => deriveBackgroundColor(type)};
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.15), 0 5px 10px 0 rgba(0, 0, 0, 0.05);
  border-radius: 0.5em;
  color: white;
  display: flex;
  font-size: 1em;
  padding: 0.5rem 1rem 0.5rem 1rem;
  transition: transform 250ms ease-in;

  & + & {
    margin-top: 1em;
  }
`);

export default SnackbarContent;

import { createContext } from 'react';
import { IModalContext } from '../interfaces/IModalContext';

export default createContext<IModalContext>({
  modal: {
    isOpen: false,
    name: null,
  },
  modalNode: null,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  openModal: (name: string, data?: unknown): void => {
    throw new Error('openModal: (): void not implemented');
  },
  closeModal: (): void => {
    throw new Error('closeModal: (): void not implemented');
  },
});

import styled from 'styled-components';

export const LabelText = styled.span`
  bottom: 2px;
  color: #354144;
  font-size: 14px;
  left: 5px;
  margin-right: 12px;
  position: relative;
`;

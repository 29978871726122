import React, { ChangeEvent, InputHTMLAttributes } from 'react';
import PropTypes from 'prop-types';
import { SwitchWrapper } from './components/SwitchWrapper';
import { SwitchLabel } from './components/SwitchLabel';
import { StyledSwitch } from './components/StyledSwitch';
import { LabelText } from './components/LabelText';
import { InputWrapper } from '../InputWrapper';

interface IProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  name: string;
  label: string;
  className?: string;
  checked?: boolean;
  defaultChecked?: boolean;
  disabled?: boolean;
  register?: (e: unknown, v: unknown) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>, value: boolean) => void;
}

const Switch = ({
  name,
  label,
  className,
  checked,
  defaultChecked,
  disabled,
  onChange,
  register,
  ...rest
}: IProps): JSX.Element => {
  const selected = checked || defaultChecked || false;
  const checkClass = selected ? 'checked' : '';

  const onSelect = (e: ChangeEvent<HTMLInputElement>): void => {
    const { checked } = e.target;
    if (onChange) {
      onChange(e, checked);
    }
  };

  return (
    <InputWrapper>
      <LabelText>{label}</LabelText>
      <SwitchWrapper className={`${checkClass}`}>
        <StyledSwitch
          type="checkbox"
          name={name}
          className={className}
          checked={selected}
          ref={(e): void => {
            if (register) {
              register(e, undefined);
            }
          }}
          disabled={disabled}
          onChange={onSelect}
          aria-label={name}
          data-testid="checkbox-switch"
          {...rest}
        />
        <SwitchLabel />
      </SwitchWrapper>
    </InputWrapper>
  );
};

Switch.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  register: PropTypes.func,
  onChange: PropTypes.func,
};

Switch.displayName = 'Switch';

export default Switch;

import { typographyVariantConfig } from 'shared/config/typographyVariant.config';
import { TypographyConfig } from 'shared/types/typography';

export const HeadingConfig: TypographyConfig = {
  variants: typographyVariantConfig,
  sizes: {
    xs: {
      fontSize: '14px',
      lineHeight: '120%',
      fontWeight: 'bold',
    },
    sm: {
      fontSize: '16px',
      lineHeight: '120%',
      fontWeight: 'bold',
    },
    md: {
      fontSize: '20px',
      lineHeight: '120%',
      fontWeight: 'bold',
    },
    lg: {
      fontSize: ['24px', '30px'],
      lineHeight: ['133%', '120%'],
      fontWeight: 'bold',
    },
    xl: {
      fontSize: ['30px', '36px'],
      lineHeight: ['133%', '120%'],
      fontWeight: 'bold',
    },
    '2xl': {
      fontSize: ['36px', '48px'],
      lineHeight: ['120%', '100%'],
      fontWeight: 'bold',
    },
    '3xl': {
      fontSize: ['48px', '60px'],
      lineHeight: '100%',
      fontWeight: 'bold',
    },
    '4xl': {
      fontSize: ['60px', '72px'],
      lineHeight: '100%',
      fontWeight: 'bold',
    },
  },
};

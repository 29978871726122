import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  position: absolute;
  top: 20px;
  right: -4px;
  outline: none;
  border: none;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
  height: 24px;
  margin: 0;
  padding: 0;
  width: 24px;
  transition: all 0.2s ease-in;
`;

interface IModalExitButton {
  onClick: () => void;
}

export const ModalExitButton = ({ onClick }: IModalExitButton): JSX.Element => (
  <StyledButton onClick={onClick}>
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12.5" cy="12.5" r="12.5" fill="#272424" />
      <path
        d="M8.60989 7.39012C8.27306 7.05329 7.72696 7.05329 7.39013 7.39012C7.0533 7.72695 7.0533 8.27305 7.39013 8.60988L8.60989 7.39012ZM17.2473 18.467C17.5841 18.8039 18.1302 18.8039 18.467 18.467C18.8039 18.1302 18.8039 17.5841 18.467 17.2473L17.2473 18.467ZM7.39013 8.60988L17.2473 18.467L18.467 17.2473L8.60989 7.39012L7.39013 8.60988Z"
        fill="#F7F3F3"
      />
      <path
        d="M17.2473 7.39012C17.5841 7.05329 18.1302 7.05329 18.467 7.39012C18.8039 7.72695 18.8039 8.27305 18.467 8.60988L17.2473 7.39012ZM8.60988 18.467C8.27305 18.8039 7.72695 18.8039 7.39012 18.467C7.05329 18.1302 7.05329 17.5841 7.39012 17.2473L8.60988 18.467ZM18.467 8.60988L8.60988 18.467L7.39012 17.2473L17.2473 7.39012L18.467 8.60988Z"
        fill="#F7F3F3"
      />
    </svg>
  </StyledButton>
);

import { Props } from 'framer-motion/types/types';
import { LogoColor } from './logo.types';
import {
  BlackLogo,
  BlackLogoIcon,
  WhiteLogo,
  WhiteLogoIcon,
  SalmonLogo,
  SalmonLogoIcon,
  SalmonLightLogo,
  SalmonLightLogoIcon,
  TealLogo,
  TealLogoIcon,
  TealLightLogo,
  TealLightLogoIcon,
  BrandLogo,
  BrandLogoIcon,
  BrandLightLogo,
  BrandLightLogoIcon,
} from './logos';

export const logoIconMap: Record<LogoColor, React.FC<Props>> = {
  black: BlackLogoIcon,
  white: WhiteLogoIcon,
  salmon: SalmonLogoIcon,
  salmonLight: SalmonLightLogoIcon,
  teal: TealLogoIcon,
  tealLight: TealLightLogoIcon,
  brand: BrandLogoIcon,
  brandLight: BrandLightLogoIcon,
};

export const fullLogoMap: Record<LogoColor, React.FC<Props>> = {
  black: BlackLogo,
  white: WhiteLogo,
  salmon: SalmonLogo,
  salmonLight: SalmonLightLogo,
  teal: TealLogo,
  tealLight: TealLightLogo,
  brand: BrandLogo,
  brandLight: BrandLightLogo,
};

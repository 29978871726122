import styled from 'styled-components';
import { Icon } from './Icon';

export const Input = styled.input`
  display: none;
  position: absolute;
  visibility: hidden;

  &:checked + ${Icon} {
    &::after {
      height: 10px;
      left: 2px;
      opacity: 1;
      top: 2px;
      width: 10px;
    }
  }
`;

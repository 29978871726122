import { MutableRefObject } from 'react';

export const deriveMaxWidth = ({ size }: { size: string }): string => {
  if (size === 'xs') {
    return '17.125rem'; // 274px
  }

  if (size === 'sm') {
    return '27.125rem'; // 434px
  }

  if (size === 'md') {
    return '37.5rem'; // 600px
  }

  return '56.25rem'; // 900px
};

export const containsClick = (e: MouseEvent) => (ref: MutableRefObject<HTMLDivElement | null>): boolean => {
  return (ref && ref.current && ref.current.contains(e.target as Node)) || false;
};

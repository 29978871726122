import styled from 'styled-components';

export const SwitchLabel = styled.label`
  background: #dde2e3;
  border-radius: 15px;
  cursor: pointer;
  height: 10px;
  left: 0;
  position: absolute;
  top: 7px;
  width: 35px;
  z-index: -1;

  &::after {
    background: #ffffff;
    border: 1px solid #dde2e3;
    border-radius: 50%;
    content: '';
    display: block;
    height: 16px;
    margin-top: -4px;
    width: 16px;
    transition: 0.2s;
  }
`;

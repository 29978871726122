import { createContext } from 'react';
import { ISnackbarContext } from '../interfaces/ISnackbarContext';
import { ISnackbar } from '../interfaces/ISnackbar';

export default createContext<ISnackbarContext>({
  snackbars: [],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  addSnackbar: (snackbar: ISnackbar): void => {
    throw new Error('addSnackbar: (snackbar: ISnackbar): void not implemented');
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  removeSnackbar: (id: number) => {
    throw new Error('removeSnackbar: (id: number) not implemented');
  },
});

import styled from 'styled-components';

export const CalendarContainer = styled.div`
  background-color: #f9f9fa;
  border: #bcc5c8;
  display: inline-block;
  left: 0;
  margin-bottom: 1.5rem;
  position: absolute;
  top: 54px;
  z-index: 1000;
`;

/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars*/
import { createContext } from 'react';
import { IDatePickerContext } from '../interfaces/IDatePickerContext';

export const DatePickerContext = createContext<IDatePickerContext>({
  focusedDate: null,
  isDateFocused: (date: Date): boolean => {
    return false;
  },
  isDateSelected: (date: Date): boolean => {
    return false;
  },
  isDateHovered: (date: Date): boolean => {
    return false;
  },
  isDateBlocked: (date: Date): boolean => {
    return false;
  },
  isFirstOrLastSelectedDate: (date: Date): boolean => {
    return false;
  },
  onDateFocus: (date: Date): void => {},
  onDateHover: (date: Date): void => {},
  onDateSelect: (date: Date): Date => {
    return new Date();
  },
});

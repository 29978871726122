import React, { useState, ChangeEvent, InputHTMLAttributes, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { ValidationOptions } from 'react-hook-form';
import { CheckboxContainer } from './components/CheckboxContainer';
import { Icon } from './components/Icon';
import { HiddenCheckbox } from './components/HiddenCheckbox';
import { LabelText } from './components/LabelText';
import { StyledCheckbox } from './components/StyledCheckbox';
import { InputWrapper } from '../InputWrapper';

interface IProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  name: string;
  label: ReactNode | string;
  className?: string;
  checked?: boolean;
  defaultChecked?: boolean;
  disabled?: boolean;
  error?: string | undefined;
  validation?: ValidationOptions;
  register?: (e: unknown, v: unknown) => void;
  onChange?: (value: boolean) => void;
}

const Checkbox = ({
  name,
  label,
  className,
  checked,
  defaultChecked,
  disabled,
  error,
  validation,
  onChange,
  register,
  ...rest
}: IProps): JSX.Element => {
  const [selected, setSelected] = useState<boolean>(checked || defaultChecked || false);

  const disabledClass = disabled ? 'disabled' : '';
  const errorClass = error ? 'error' : '';
  const checkClass = selected ? 'checked' : '';

  const onSelect = (e: ChangeEvent<HTMLInputElement>): void => {
    const { checked } = e.target;
    setSelected(checked);
    if (onChange) {
      onChange(checked);
    }
  };

  return (
    <InputWrapper>
      <CheckboxContainer className={`${errorClass} ${disabledClass} ${checkClass}`}>
        <HiddenCheckbox
          type="checkbox"
          className={className}
          name={name}
          checked={selected}
          ref={(e): void => {
            if (register) {
              register(e, validation);
            }
          }}
          disabled={disabled}
          onChange={onSelect}
          aria-label={name}
          data-testid="checkbox-input"
          {...rest}
        />
        <StyledCheckbox checked={selected}>
          <Icon viewBox="0 0 24 24">
            <polyline points="20 6 9 17 4 12" />
          </Icon>
        </StyledCheckbox>
        <LabelText>{label}</LabelText>
      </CheckboxContainer>
    </InputWrapper>
  );
};

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]).isRequired,
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  validation: PropTypes.object,
  register: PropTypes.func,
  onChange: PropTypes.func,
};

Checkbox.displayName = 'Checkbox';

export default Checkbox;

import { ComponentStyleConfig } from '@chakra-ui/react';

export const ButtonConfig: ComponentStyleConfig = {
  baseStyle: {
    border: '1px solid transparent',
    color: 'text.onContrast',
    svg: {
      color: 'text.onContrast',
    },
  },
  variants: {
    primary: {
      bg: 'background.primary',
      _hover: {
        bg: 'background.primary.hover',
        _disabled: {
          bg: 'background.primary',
        },
      },
      _active: {
        bg: 'background.primary.pressed',
      },
    },
    primaryOnContrast: {
      bg: 'background.primaryContrast',
      color: 'text.default',
      svg: {
        color: 'text.default',
      },
      _hover: {
        bg: 'background.primaryContrast.hover',
        _disabled: {
          bg: 'background.primaryContrast',
        },
      },
      _active: {
        bg: 'background.primaryContrast.pressed',
      },
    },
    primaryDestructive: {
      bg: 'background.danger',
      borderColor: 'transparent',
      _hover: {
        bg: 'background.danger.hover',
        _disabled: {
          bg: 'background.danger',
        },
      },
      _active: {
        bg: 'background.danger.pressed',
      },
    },
    primaryDestructiveOnContrast: {
      bg: 'background.dangerContrast',
      color: 'text.default',
      svg: {
        color: 'text.default',
      },
      _hover: {
        bg: 'background.dangerContrast.hover',
        _disabled: {
          bg: 'background.dangerContrast',
        },
      },
      _active: {
        bg: 'background.dangerContrast.pressed',
      },
    },
    secondary: {
      bg: 'transparent',
      borderColor: 'border.strong',
      color: 'text.default',
      svg: {
        color: 'text.default',
      },
      _hover: {
        bg: 'background.alpha.hover',
        _disabled: {
          bg: 'transparent',
        },
      },
      _active: {
        bg: 'background.alpha.pressed',
      },
    },
    secondaryOnContrast: {
      bg: 'transparent',
      borderColor: 'border.strongContrast',
      _hover: {
        bg: 'background.alphaContrast.hover',
        _disabled: {
          bg: 'transparent',
        },
      },
      _active: {
        bg: 'background.alphaContrast.pressed',
      },
    },
    secondaryDestructive: {
      color: 'text.danger',
      bg: 'transparent',
      borderColor: 'border.danger',
      svg: {
        color: 'text.danger',
      },
      _hover: {
        bg: 'background.alpha.hover',
        _disabled: {
          bg: 'transparent',
        },
      },
      _active: {
        bg: 'background.alpha.pressed',
      },
    },
    secondaryDestructiveOnContrast: {
      bg: 'transparent',
      color: 'text.dangerOnContrast',
      borderColor: 'border.dangerContrast',
      svg: {
        color: 'text.dangerOnContrast',
      },
      _hover: {
        bg: 'background.alphaContrast.hover',
        _disabled: {
          bg: 'transparent',
        },
      },
      _active: {
        bg: 'background.alphaContrast.pressed',
      },
    },
    secondarySubtle: {
      bg: 'background.default',
      borderColor: 'border.default',
      color: 'text.default',
      svg: {
        color: 'text.default',
      },
      _hover: {
        bg: 'background.subtle',
        _disabled: {
          bg: 'background.default',
        },
      },
      _active: {
        bg: 'background.lessSubtle',
      },
    },
    secondarySubtleOnContrast: {
      bg: 'transparent',
      borderColor: 'border.contrast',
      _hover: {
        bg: 'background.alphaContrast.hover',
        _disabled: {
          bg: 'transparent',
        },
      },
      _active: {
        bg: 'background.alphaContrast.pressed',
      },
    },
    secondarySubtleDestructive: {
      bg: 'background.default',
      color: 'text.danger',
      borderColor: 'border.default',
      svg: {
        color: 'text.danger',
      },
      _hover: {
        bg: 'background.subtle',
        _disabled: {
          bg: 'background.default',
        },
      },
      _active: {
        bg: 'background.lessSubtle',
      },
    },
    secondarySubtleDestructiveOnContrast: {
      bg: 'background.contrast',
      color: 'text.dangerOnContrast',
      borderColor: 'border.contrast',
      svg: {
        color: 'text.dangerOnContrast',
      },
      _hover: {
        bg: 'background.subtleContrast',
        _disabled: {
          bg: 'background.contrast',
        },
      },
      _active: {
        bg: 'background.moreSubtleContrast',
      },
    },
    tertiary: {
      color: 'text.default',
      bg: 'background.alpha',
      svg: {
        color: 'text.default',
      },
      _hover: {
        bg: 'background.alpha.hover',
        _disabled: {
          bg: 'background.alpha',
        },
      },
      _active: {
        bg: 'background.alpha.pressed',
      },
    },
    tertiaryOnContrast: {
      bg: 'background.alphaContrast',
      _hover: {
        bg: 'background.alphaContrast.hover',
        _disabled: {
          bg: 'background.alphaContrast',
        },
      },
      _active: {
        bg: 'background.alphaContrast.pressed',
      },
    },
    tertiaryDestructive: {
      bg: 'background.dangerAlpha',
      color: 'text.danger',
      svg: {
        color: 'text.danger',
      },
      _hover: {
        bg: 'background.dangerAlpha.hover',
        _disabled: {
          bg: 'background.dangerAlpha',
        },
      },
      _active: {
        bg: 'background.dangerAlpha.pressed',
      },
    },
    tertiaryDestructiveOnContrast: {
      bg: 'background.dangerAlphaContrast',
      color: 'text.dangerOnContrast',
      svg: {
        color: 'text.dangerOnContrast',
      },
      _hover: {
        bg: 'background.dangerAlphaContrast.hover',
        _disabled: {
          bg: 'background.dangerAlphaContrast',
        },
      },
      _active: {
        bg: 'background.dangerAlphaContrast.pressed',
      },
    },
    ghost: {
      color: 'text.interactive',
      bg: 'transparent',
      svg: {
        color: 'text.interactive',
      },
      _hover: {
        bg: 'background.primaryAlpha.hover',
        _disabled: {
          bg: 'transparent',
        },
      },
      _active: {
        bg: 'background.primaryAlpha.pressed',
      },
    },
    ghostOnContrast: {
      color: 'text.interactiveOnContrast',
      bg: 'transparent',
      svg: {
        color: 'text.interactiveOnContrast',
      },
      _hover: {
        bg: 'background.primaryAlphaContrast.hover',
        _disabled: {
          bg: 'transparent',
        },
      },
      _active: {
        bg: 'background.primaryAlphaContrast.pressed',
      },
    },
    ghostNeutral: {
      bg: 'transparent',
      color: 'text.default',
      svg: {
        color: 'text.default',
      },
      _hover: {
        bg: 'background.alpha.hover',
        _disabled: {
          bg: 'transparent',
        },
      },
      _active: {
        bg: 'background.alpha.pressed',
      },
    },
    ghostNeutralOnContrast: {
      bg: 'transparent',
      _hover: {
        bg: 'background.alphaContrast.hover',
        _disabled: {
          bg: 'transparent',
        },
      },
      _active: {
        bg: 'background.alphaContrast.pressed',
      },
    },
    ghostDestructive: {
      bg: 'transparent',
      color: 'text.danger',
      svg: {
        color: 'text.danger',
      },
      _hover: {
        bg: 'background.dangerAlpha.hover',
        _disabled: {
          bg: 'transparent',
        },
      },
      _active: {
        bg: 'background.dangerAlpha.pressed',
      },
    },
    ghostDestructiveOnContrast: {
      bg: 'transparent',
      color: 'text.dangerOnContrast',
      svg: {
        color: 'text.dangerOnContrast',
      },
      _hover: {
        bg: 'background.dangerAlphaContrast.hover',
        _disabled: {
          bg: 'transparent',
        },
      },
      _active: {
        bg: 'background.dangerAlphaContrast.pressed',
      },
    },
  },
  sizes: {
    lg: {
      p: '10px 20px',
    },
    md: {
      p: '8px 16px',
    },
    sm: {
      p: '6px 12px',
    },
    xs: {
      p: '3px 8px',
    },
  },
};

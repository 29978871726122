import { ComponentStyleConfig } from '@chakra-ui/react';

export const TabsConfig: ComponentStyleConfig = {
  baseStyle: {
    tab: {
      color: 'text.subtle',
      fontWeight: 'bold',
      _disabled: {
        color: 'text.default',
        borderColor: 'border.default',
        _hover: {
          background: 'transparent',
        },
      },
      _focus: {
        boxShadow: 0,
      },
    },
    tabpanel: {
      padding: 0,
    },
  },
  sizes: {
    xs: {
      tab: {
        py: 2,
        px: 2,
      },
    },
    sm: {
      tab: {
        py: 3,
        px: 3,
      },
    },
    md: {
      tab: {
        py: 4,
        px: 5,
      },
    },
    lg: {
      tab: {
        py: 5,
        px: 6,
      },
    },
  },
  variants: {
    line: {
      tablist: {
        borderBottom: '3px solid',
        borderColor: 'border.default',
      },
      tab: {
        borderBottom: '3px solid',
        borderBottomColor: 'border.default',
        marginBottom: '-3px',
        _selected: {
          color: 'text.default',
          borderColor: 'border.info',
        },
        _active: {
          background: 'transparent',
        },
        _hover: {
          background: 'background.alpha',
        },
      },
    },
  },
};

import styled from 'styled-components';
import { IMenuItem } from '../interfaces/IMenuItem';

const MenuItem = styled.div<IMenuItem>`
  cursor: pointer;
  background-color: ${({ isSelected }: IMenuItem): string => {
    return isSelected ? '#DDE2E3' : 'transparent';
  }};
  border-bottom: solid 1px #DDE2E3};
  padding: 1em;

  &:last-child {
    border-bottom: none;
  }
`;

export default MenuItem;

import { IOption } from 'components/Inputs/Select';

export const pad = (num: number): string => {
  let s = String(num);
  if (s.length < 2) {
    s = '0' + s;
  }
  return s;
};

export const getMinuteOptions = (increment = 5): IOption[] => {
  const options: IOption[] = [];
  const hour = 60;
  let minute = 0;

  while (minute < hour) {
    options.push({
      label: pad(minute),
      value: minute,
    });
    minute = minute + increment;
  }
  return options;
};

export const hourOptions: IOption[] = [
  {
    label: '12',
    value: 0,
  },
  {
    label: '1',
    value: 1,
  },
  {
    label: '2',
    value: 2,
  },
  {
    label: '3',
    value: 3,
  },
  {
    label: '4',
    value: 4,
  },
  {
    label: '5',
    value: 5,
  },
  {
    label: '6',
    value: 6,
  },
  {
    label: '7',
    value: 7,
  },
  {
    label: '8',
    value: 8,
  },
  {
    label: '9',
    value: 9,
  },
  {
    label: '10',
    value: 10,
  },
  {
    label: '11',
    value: 11,
  },
];

export const scrollIntoView = (parent: Element, child: Element): void => {
  const parentBounding = parent.getBoundingClientRect(),
    clientBounding = child.getBoundingClientRect();

  const parentBottom = parentBounding.bottom,
    parentTop = parentBounding.top,
    clientBottom = clientBounding.bottom,
    clientTop = clientBounding.top;

  if (parentTop >= clientTop) {
    scrollTo(parent, -(parentTop - clientTop), 50);
  } else if (clientBottom > parentBottom) {
    scrollTo(parent, clientBottom - parentBottom, 50);
  }
};

const scrollTo = (element: Element, to: number, duration: number): void => {
  const start = element.scrollTop;
  let currentTime = 0;
  const increment = 50;

  const animateScroll = (): void => {
    currentTime += increment;

    const val = easeInOutQuad(currentTime, start, to, duration);
    element.scrollTop = val;

    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    }
  };

  animateScroll();
};

// Function for smooth scroll animation with the time duration
const easeInOutQuad = (time: number, startPos: number, endPos: number, duration: number): number => {
  time /= duration / 2;

  if (time < 1) return (endPos / 2) * time * time + startPos;
  time--;
  return (-endPos / 2) * (time * (time - 2) - 1) + startPos;
};

import styled from 'styled-components';

export const Field = styled.div`
  background-color: #f9f9fa;
  border: solid 1px #dde2e3;
  border-radius: 4px;
  box-sizing: border-box;
  height: 50px;
  position: relative;
  transition: 0.3s all;
  width: 100%;

  &.disabled {
    background-color: #bcc5c8;
  }

  &.focussed {
    border: 1px solid #3ca0b4;
  }

  &.focussed input,
  &.value input {
    padding: 22px 16px 8px 16px;
    &::-webkit-input-placeholder {
      color: #f9f9fa;
    }

    &::-moz-placeholder {
      color: #f9f9fa;
    }

    &:-ms-input-placeholder {
      color: #f9f9fa;
    }

    &:-moz-placeholder {
      color: #f9f9fa;
    }
  }

  &.focussed input + label,
  &.value input + label {
    color: #a9b5b8;
    opacity: 1;
    top: 4px;
  }

  &.value input + label {
    color: #39adc3;
  }

  &.error input + label {
    color: #e01f1f;
    opacity: 1;
    top: 4px;
  }

  &.error {
    border: 2px solid #e01f1f;
  }
`;

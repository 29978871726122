import styled from 'styled-components';
import { SwitchLabel } from './SwitchLabel';

export const StyledSwitch = styled.input`
  border-radius: 15px;
  cursor: pointer;
  height: 18px;
  opacity: 0;
  width: 35px;
  z-index: 1;

  &:checked + ${SwitchLabel} {
    background-color: #f9f9fa;
    border: 1px solid #78cbdc;
    &::after {
      border: none;
      background-color: #3ca0b4;
      border: 1px solid #3ca0b4;
      border-radius: 50%;
      content: '';
      display: block;
      height: 16px;
      margin-left: 18px;
      margin-top: -4px;
      transition: 0.2s;
      width: 16px;
    }
  }
`;

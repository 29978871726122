import React, { useEffect, useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { format, addMinutes } from 'date-fns';
import { IOption } from 'components/Inputs/Select';
import { Meridiem } from './enums/Meridiem';
import { getMinuteOptions, hourOptions, scrollIntoView } from './utils';

interface ITimePickerProps {
  defaultDate: Date;
  timeLabelFormat?: string;
  minuteIncrement?: number;
  onDateChanged: (e: React.MouseEvent, date: Date) => void;
  onIntervalClicked?: (e: React.MouseEvent, date: Date) => void;
  showIntervalPicker?: boolean;
}

const TimePicker = ({
  defaultDate,
  timeLabelFormat = 'h:mm a',
  minuteIncrement = 5,
  showIntervalPicker = false,
  onIntervalClicked,
  onDateChanged,
}: ITimePickerProps): JSX.Element => {
  const [date, setDate] = useState<Date>(defaultDate);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const hourParentContainerRef = useRef<HTMLUListElement | null>(null);
  const minuteParentContainerRef = useRef<HTMLUListElement | null>(null);
  const selectedHourRef = useRef<HTMLLIElement | null>(null);
  const selectedMinuteRef = useRef<HTMLLIElement | null>(null);

  useEffect(() => {
    setDate(defaultDate);
  }, [defaultDate, setDate]);

  const minuteOptions = useMemo(() => {
    return getMinuteOptions(minuteIncrement);
  }, []);

  const selectedHour = useMemo(() => {
    return date.getHours() % 12;
  }, [date]);

  useEffect(() => {
    if (selectedHourRef?.current && hourParentContainerRef?.current) {
      scrollIntoView(hourParentContainerRef.current, selectedHourRef.current);
    }
  }, [selectedHour, isOpen, selectedHourRef]);

  const selectedMinute = useMemo(() => {
    return date.getMinutes();
  }, [date]);

  useEffect(() => {
    if (selectedMinuteRef?.current && minuteParentContainerRef?.current) {
      scrollIntoView(minuteParentContainerRef.current, selectedMinuteRef.current);
    }
  }, [selectedMinute, isOpen, selectedMinuteRef]);

  const selectedMeridiem = useMemo(() => {
    return date.getHours() >= 12 ? Meridiem.PM : Meridiem.AM;
  }, [date]);

  const onHourChanged = (e: React.MouseEvent, twelveHourValue: number): void => {
    const dateCopy = new Date(date.getTime());
    if (selectedMeridiem === Meridiem.AM) {
      dateCopy.setHours(twelveHourValue);
    } else {
      dateCopy.setHours(twelveHourValue + 12);
    }
    setDate(dateCopy);
    onDateChanged(e, dateCopy);
  };

  const onMinuteChanged = (e: React.MouseEvent, minute: number): void => {
    const dateCopy = new Date(date.getTime());
    dateCopy.setMinutes(minute);
    setDate(dateCopy);
    onDateChanged(e, dateCopy);
  };

  const handleIntervalClicked = (e: React.MouseEvent, intervalInMinutes: number): void => {
    const dateCopy = new Date(date.getTime());
    if (onIntervalClicked) {
      onIntervalClicked(e, addMinutes(dateCopy, intervalInMinutes));
    }
  };

  const onMeridiemChanged = (e: React.MouseEvent, meridiem: Meridiem): void => {
    const dateCopy = new Date(date.getTime());
    if (meridiem === Meridiem.AM) {
      const hours = dateCopy.getHours() % 12;
      dateCopy.setHours(hours);
    } else {
      const hours = dateCopy.getHours() + 12;
      dateCopy.setHours(hours);
    }
    setDate(dateCopy);
    onDateChanged(e, dateCopy);
  };

  useEffect(() => {
    const handleClick = (e: MouseEvent): void => {
      // You clicked in the modal, so don't close
      if (containerRef?.current && !containerRef.current.contains(e.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClick);

    return (): void => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [containerRef]);

  return (
    <TimePickerContainer>
      <IconContainer>
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
          <path
            fill="#878890"
            d="M3.147.003c-.193-.017-.392.04-.552.172L.27 2.1c-.32.266-.361.737-.092 1.053.27.316.747.355 1.068.09L3.57 1.318c.32-.266.362-.736.092-1.051C3.528.109 3.34.019 3.147.003zm8.705 0c-.193.016-.38.106-.515.264-.27.316-.228.785.093 1.051l2.323 1.925c.321.265.8.226 1.07-.09.269-.316.227-.787-.094-1.053L12.406.175c-.16-.133-.36-.189-.554-.172zM7.5 1.56C3.73 1.56.676 4.57.676 8.28.676 11.992 3.73 15 7.5 15c3.769 0 6.823-3.008 6.823-6.72 0-3.711-3.054-6.72-6.823-6.72zm0 2.987c.418 0 .758.334.758.747v3.272l1.935.952c.375.185.527.633.34 1.002-.188.369-.643.518-1.018.334L7.16 9.694c-.256-.125-.419-.384-.419-.667V5.294c0-.413.34-.747.759-.747z"
          />
        </svg>
      </IconContainer>
      <TextContainer onClick={(): void => setIsOpen(!isOpen)}>{format(date, timeLabelFormat)}</TextContainer>
      {isOpen && (
        <MenuContainer ref={containerRef}>
          <MenuContent>
            <MenuList ref={hourParentContainerRef}>
              {hourOptions.map((hourOption: IOption) => {
                return (
                  <MenuListItem
                    key={hourOption.value}
                    ref={hourOption.value === selectedHour ? selectedHourRef : null}
                    onClick={(e: React.MouseEvent): void => onHourChanged(e, Number(hourOption.value))}
                    data-value={hourOption.value}
                    className={hourOption.value === selectedHour ? 'selected' : ''}
                  >
                    {hourOption.label}
                  </MenuListItem>
                );
              })}
            </MenuList>
            <MenuList ref={minuteParentContainerRef}>
              {minuteOptions.map((minuteOption: IOption) => {
                return (
                  <MenuListItem
                    key={minuteOption.value}
                    ref={minuteOption.value === selectedMinute ? selectedMinuteRef : null}
                    onClick={(e: React.MouseEvent): void => onMinuteChanged(e, Number(minuteOption.value))}
                    data-value={minuteOption.value}
                    className={minuteOption.value === selectedMinute ? 'selected' : ''}
                  >
                    {minuteOption.label}
                  </MenuListItem>
                );
              })}
            </MenuList>
            <MenuList>
              <MenuListItem
                onClick={(e: React.MouseEvent): void => onMeridiemChanged(e, Meridiem.AM)}
                className={selectedMeridiem === Meridiem.AM ? 'selected' : ''}
              >
                {Meridiem.AM}
              </MenuListItem>
              <MenuListItem
                onClick={(e: React.MouseEvent): void => onMeridiemChanged(e, Meridiem.PM)}
                className={selectedMeridiem === Meridiem.PM ? 'selected' : ''}
              >
                {Meridiem.PM}
              </MenuListItem>
            </MenuList>
          </MenuContent>
          {showIntervalPicker && (
            <MenuFooter>
              <MenuFooterHeader>Duration Intervals...</MenuFooterHeader>
              <MenuFooterButtonContainer>
                <IntervalButton onClick={(e: React.MouseEvent): void => handleIntervalClicked(e, 30)}>
                  30 min
                </IntervalButton>
                <IntervalButton onClick={(e: React.MouseEvent): void => handleIntervalClicked(e, 60)}>
                  60 min
                </IntervalButton>
                <IntervalButton onClick={(e: React.MouseEvent): void => handleIntervalClicked(e, 90)}>
                  90 min
                </IntervalButton>
              </MenuFooterButtonContainer>
            </MenuFooter>
          )}
        </MenuContainer>
      )}
    </TimePickerContainer>
  );
};

export default TimePicker;

TimePicker.propTypes = {
  defaultDate: PropTypes.object.isRequired,
  timeLabelFormat: PropTypes.string,
  minuteIncrement: PropTypes.number,
  onDateChanged: PropTypes.func.isRequired,
};

const TimePickerContainer = styled.div`
  cursor: pointer;
`;

const IconContainer = styled.span`
  margin-right: 5px;

  svg {
    position: relative;
    top: 1px;
  }
`;

const TextContainer = styled.span`
  color: #7f879a;
  font-size: 0.875rem;
`;

const MenuContainer = styled.div`
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 6px 0 rgba(20, 79, 90, 0.22);
  left: 18px;
  position: relative;
  top: 5px;
  width: 155px;
`;

const MenuContent = styled.div`
  display: flex;
  height: 100px;
  justify-content: space-between;
  position: relative;
`;

const MenuFooter = styled.div`
  border-top: 1px solid #e8e9e9;
  height: 50px;
  padding: 8px;
`;

const MenuList = styled.ul`
  flex: 1;
  height: 100%;
  list-style: none;
  margin: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 0;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    height: 0;
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    margin: 2px 0;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    box-shadow: rgba(255, 255, 255, 0.3) 0 0 0 1px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.35);
  }
`;

const MenuListItem = styled.li`
  color: #7f879a;
  font-size: 0.75rem;
  margin: 0;
  padding: 5px 0 5px 0;
  text-align: center;

  &.selected {
    background-color: #e6f4f6;
  }
`;

const MenuFooterHeader = styled.p`
  color: #7f879a;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.1px;
  margin-bottom: 4px;
`;

const MenuFooterButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const IntervalButton = styled.button`
  background: none;
  border: none;
  color: #7f879a;
  font-size: 12px;
  letter-spacing: 0.11px;
  padding: 2px 4px;

  &:hover {
    border-radius: 4px;
    background-color: #e0f1f4;
  }
`;

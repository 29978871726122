import styled from 'styled-components';

export const SelectInput = styled.select`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #f9f9fa;
  border: solid 1px #dde2e3;
  border-radius: 4px;
  box-sizing: border-box;
  color: #354144;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  height: 50px;
  padding-left: 5px;
  position: relative;
  transition: 0.3s all;
  width: 100%;
  text-indent: 7px;

  &.disabled {
    background-color: #bcc5c8;
  }

  &:focus {
    outline: none;
    border: 1px solid #3ca0b4;
  }

  &.error {
    border: 2px solid #e01f1f;
  }
`;

import React, { useState, useRef, useEffect, InputHTMLAttributes, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import { useDatepicker, getInputValue, parseDate, START_DATE, OnDatesChangeProps } from '@datepicker-react/hooks';
import { DatePickerContext } from './contexts/DatePickerContext';
import { isDate } from './utils';
import { GridContainer } from './components/GridContainer';
import { ActionsContainer } from './components/ActionsContainer';
import { CalendarContainer } from './components/CalendarContainer';
import { CalendarControlContainer } from './components/CalendarControlContainer';
import Month from './components/Month';
import NavButton from './components/NavButton';
import TextInput from '../Inputs/TextInput';

interface IDatePickerProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
  date: Date | null;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onDateChange?: (date: Date | null) => void;
  displayFormat?: string;
  error?: string | undefined;
}

const DatePicker = ({
  name,
  label,
  date,
  onDateChange,
  onChange,
  displayFormat = 'MM/dd/yyyy',
  ...rest
}: IDatePickerProps): JSX.Element => {
  const [searchString, setSearchString] = useState<string | number | undefined>(getInputValue(date, displayFormat, ''));
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  const [state, setState] = useState<OnDatesChangeProps>({
    startDate: date,
    endDate: date,
    focusedInput: START_DATE,
  });

  useEffect(() => {
    const handleClick = (): void => {
      if (ref && !ref?.current?.contains(event?.target as Node)) setIsOpen(false);
    };
    document.addEventListener('mousedown', handleClick);

    return (): void => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [ref]);

  const onDatesChange = (data: OnDatesChangeProps): void => {
    const { focusedInput, startDate, endDate } = data;
    if (!focusedInput) {
      setState({ startDate, endDate, focusedInput: START_DATE });
    } else {
      setState(data);
    }
    setSearchString(getInputValue(startDate, displayFormat, ''));
    // setIsOpen(false);

    if (onDateChange) onDateChange(startDate);

    const e = {
      target: {
        value: getInputValue(startDate, displayFormat, ''),
      },
    } as ChangeEvent<HTMLInputElement>;
    onChange(e);
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const dateValue = e.target.value;
    setSearchString(dateValue);
    onChange(e);

    const parsedDate = parseDate(dateValue, displayFormat, new Date());

    if (dateValue.length === displayFormat.length && parsedDate instanceof Date && isDate(parsedDate)) {
      onDateSelect(parsedDate);
      setSearchString(getInputValue(parsedDate, displayFormat, ''));
    }
  };

  const onInputClick = (): void => {
    if (!isOpen) setIsOpen(true);
  };

  const onFocus = (): void => {
    if (!isOpen) setIsOpen(true);
  };

  const onKeyDown = (e: React.KeyboardEvent): void => {
    if (e.key === 'Enter' && isOpen) {
      setIsOpen(false);
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const { startDate, endDate, focusedInput } = state;
  const {
    firstDayOfWeek,
    activeMonths,
    isDateSelected,
    isDateHovered,
    isFirstOrLastSelectedDate,
    isDateBlocked,
    isDateFocused,
    focusedDate,
    onDateHover,
    onDateSelect,
    onDateFocus,
    goToPreviousMonths,
    goToNextMonths,
  } = useDatepicker({
    startDate,
    endDate,
    focusedInput,
    onDatesChange,
    numberOfMonths: 1,
    minBookingDays: 1,
    exactMinBookingDays: true,
  });
  return (
    <DatePickerContext.Provider
      value={{
        focusedDate,
        isDateFocused,
        isDateSelected,
        isDateHovered,
        isDateBlocked,
        isFirstOrLastSelectedDate,
        onDateSelect,
        onDateFocus,
        onDateHover,
      }}
    >
      <CalendarControlContainer>
        <TextInput
          {...rest}
          name={name}
          label={label}
          type="text"
          value={searchString}
          onClick={onInputClick}
          onChange={onInputChange}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          data-testid="date-picker-input"
        />

        {isOpen && (
          <CalendarContainer ref={ref}>
            <ActionsContainer>
              <NavButton onClick={goToPreviousMonths}>&lt;</NavButton>
              <NavButton onClick={goToNextMonths}>&gt;</NavButton>
            </ActionsContainer>

            <GridContainer activeMonths={activeMonths}>
              {activeMonths.map((month) => {
                return (
                  <Month
                    key={`${month.year}-${month.month}`}
                    year={month.year}
                    month={month.month}
                    firstDayOfWeek={firstDayOfWeek}
                    onDaySelected={(): void => setIsOpen(false)}
                  />
                );
              })}
            </GridContainer>
          </CalendarContainer>
        )}
      </CalendarControlContainer>
    </DatePickerContext.Provider>
  );
};

DatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  onDateChange: PropTypes.func,
  displayFormat: PropTypes.string,
  error: PropTypes.string,
};

DatePicker.defaultProps = {
  date: null,
  displayFormat: 'MM/dd/yyyy',
};

export default DatePicker;

export { getInputValue, parseDate };

import styled from 'styled-components';

export const SelectWrapper = styled.div`
  &:after {
    border-bottom: solid 1px #dde2e3;
    content: '>';
    color: #5b7074;
    font-size: 16px;
    height: 34px;
    padding: 0 10px 0 10px;
    pointer-events: none;
    position: absolute;
    right: 16px;
    top: 8px;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
`;

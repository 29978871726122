import React, { ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import { ValidationOptions } from 'react-hook-form';
import { IRadioOption } from './interfaces/IRadioOption';
import { RadioGroupWrapper } from './components/RadioGroupWrapper';
import { InputWrapper } from '../InputWrapper';
import Radio from '../Radio';

interface IProps {
  name: string;
  options: IRadioOption[];
  value?: string | number;
  disabled?: boolean;
  error?: string | undefined;
  validation?: ValidationOptions;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  register?: (e: unknown, v: unknown) => void;
}

const RadioGroup = ({
  name,
  options,
  value,
  disabled,
  error,
  validation,
  register,
  onChange,
  ...rest
}: IProps): JSX.Element => {
  const disabledClass = disabled ? 'disabled' : '';
  const errorClass = error ? 'error' : '';

  return (
    <InputWrapper>
      <RadioGroupWrapper className={`${disabledClass} ${errorClass}`}>
        {options.map((option: IRadioOption) => {
          return (
            <Radio
              key={option.value}
              name={name}
              label={option.label}
              value={option.value}
              checked={value ? option.value === value : undefined}
              disabled={disabled}
              register={register}
              validation={validation}
              onChange={onChange}
              data-testid="radio-group-input"
              {...rest}
            />
          );
        })}
      </RadioGroupWrapper>
    </InputWrapper>
  );
};

RadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  validation: PropTypes.object,
  onChange: PropTypes.func,
  register: PropTypes.func,
};

RadioGroup.displayName = 'RadioGroup';

export default RadioGroup;

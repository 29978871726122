import { ComponentStyleConfig } from '@chakra-ui/react';

export const MenuItemConfig = {
  color: 'text.default',
  cursor: 'pointer',
  fontSize: 'sm',
  px: '20px',
  py: '8px',
  outline: 0,
  transitionDuration: 'ultra-fast',
  transitionProperty: 'background',
  transitionTimingFunction: 'ease-in',
  _hover: {
    bg: 'background.primaryAlpha.hover',
  },
  _focus: {
    bg: 'background.primaryAlpha.hover',
  },
  _active: {
    color: 'text.info',
  },
  _expanded: {
    bg: 'background.alpha',
  },
  _disabled: {
    opacity: 0.4,
    cursor: 'not-allowed',
    bg: 'transparent',
  },
};

export const MenuConfig: ComponentStyleConfig = {
  baseStyle: {
    item: MenuItemConfig,
    list: {
      p: '8px 0',
      boxShadow: 'md',
      borderColor: 'boder.default',
      backgroundColor: 'background.overlay',
    },
  },
};

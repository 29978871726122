import React, { useEffect, ReactNode } from 'react';
import SnackbarContent from './SnackbarContent';
import SnackbarCloseButton from './SnackbarCloseButton';
import { MessageTypes } from '../enums/MessageTypes';

type Props = {
  children: ReactNode;
  id: number;
  type: MessageTypes;
  timeout?: number;
  removeSnackbar: (id: number) => void;
};

const Snackbar = ({ id, type = MessageTypes.Info, timeout = 2000, children, removeSnackbar }: Props): JSX.Element => {
  useEffect(() => {
    let timeoutMs: number;

    if (timeout) {
      timeoutMs = setTimeout(() => removeSnackbar(id), timeout);
    }

    return (): void => {
      if (timeoutMs) {
        clearTimeout(timeoutMs);
      }
    };
  }, [timeout, removeSnackbar, id]);

  return (
    <SnackbarContent data-testid="snackbar-content" role="alert" type={type}>
      {children}
      <SnackbarCloseButton onClick={(): void => removeSnackbar(id)}>&times;</SnackbarCloseButton>
    </SnackbarContent>
  );
};

export default Snackbar;
